import React, { useState } from 'react';
import Loader from 'components/commons/_/loader/Loader';
import ProcessList from 'components/employeePortal/process/ProcessList';
import Contacts from 'components/employeePortal/process/community/Contacts';
import ActionList from 'components/employeePortal/process/action/ActionList';
import { Tabs } from 'components/commons/_/tabs/Tabs';
import { TabList } from 'components/commons/_/tabs/TabList';
import { TabPanels } from 'components/commons/_/tabs/TabPanels';
import { TabPanel } from 'components/commons/_/tabs/TabPanel';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ActionListPlaceholderView from 'components/employeePortal/process/action/components/placeholder/ActionListPlaceholder.view';
import { wire } from 'react-hot-wire';
import UserService from 'services/user/UserService';
import EmployeeProgress from './process/employee/EmployeeProgress';
import { TTab } from 'components/commons/_/tabs/TabsContext';
import useEmployeePortalContext from './context/employeePortalContext';
import { Button } from '../_/common/button/Button';
import { useMutation } from 'react-query';
import ProcessService from 'services/onboarding/process/ProcessService';
import images from '../../assets/images';
import FilterByEmployeeModal from 'components/employeePortal/process/filter/modal/FilterByEmployeeModal';

export type PortalMobileProps = InjectedIntlProps;

export type PortalMobileWiredProps = PortalMobileProps & {
	'services.userService': UserService;
	'services.onboardingProcessService': ProcessService;
};

const PortalMobile = ({
	intl,
	'services.userService': userService,
	'services.onboardingProcessService': processService,
}: PortalMobileWiredProps) => {
	const {
		progress: {
			data: { items: processList },
		},
		employeeProgress: {
			data: { items: employeeProcessList },
			isFetching: employeeProgressIsFetching,
		},
		actions: { isFetching: actionListLoading },
		contact: {
			data: { items: contactList },
		},
		employeeList,
	} = useEmployeePortalContext();
	const [isFilterModalOpen, setFilterModalOpen] = useState(false);
	const userProcessList = processList.filter(process => userService.user()?.userProfile.id() === process.userId);
	const tabs = [
		{ content: intl.formatMessage({ id: 'portal.tasks.title' }) },
		employeeProcessList.length ? { content: intl.formatMessage({ id: 'portal.employees.yours' }) } : null,
		contactList.length ? { content: intl.formatMessage({ id: 'portal.contacts.title' }) } : null,
	].filter(tab => !!tab) as TTab[];
	const processReport = useMutation(() => processService.fetchReport('process-report'));
	const processActionsReport = useMutation(() => processService.fetchReport('process-actions'));
	const FilterIcon = images.portal.filter;

	return (
		<div className="d-flex flex-column flex-lg-row flex-row portal__content">
			{isFilterModalOpen && <FilterByEmployeeModal onSelect={() => setFilterModalOpen(false)} />}
			<Tabs tabs={tabs}>
				<TabList
					className="d-flex flex-row"
					after={
						employeeList.length >= 2 ? (
							<div
								className="flex-shrink-1 ml-auto d-flex align-items-center"
								onClick={() => setFilterModalOpen(true)}
							>
								<FilterIcon className="portal__filter" />
							</div>
						) : null
					}
				/>

				<div className="portal__spacer mt-3 mb-4" />
				<TabPanels>
					<TabPanel>
						{processList.length ? (
							<>
								{!!userProcessList.length && (
									<>
										<div className="portal__process">
											<ProcessList processList={userProcessList} expandable />
										</div>
										<div className="portal__spacer mt-5 mb-4" />
									</>
								)}
								{actionListLoading && <Loader />}
								<ActionList />
							</>
						) : (
							<ActionListPlaceholderView
								message={intl.formatMessage({ id: 'portal.process.placeholder' })}
								icon="🎈"
							/>
						)}
					</TabPanel>
					{!!employeeProcessList.length && (
						<TabPanel>
							<div className="d-flex align-items-center fs-4 mb-4">
								<span className="portal__download-report mr-2">
									{intl.formatMessage({ id: 'portal.process.report.downloadReport' })}
								</span>
								<Button
									className="download-report__button py-3 d-flex align-items-center"
									disabled={processReport.isLoading}
									isLoading={processReport.isLoading}
									onClick={() => processReport.mutate()}
								>
									<i className="fa fa-download mr-2" />
									<span>
										{intl.formatMessage({ id: 'portal.process.report.button.processReport' })}
									</span>
								</Button>
								<Button
									className="download-report__button py-3 d-flex align-items-center ml-2"
									disabled={processActionsReport.isLoading}
									isLoading={processActionsReport.isLoading}
									onClick={() => processActionsReport.mutate()}
								>
									<i className="fa fa-download mr-2" />
									<span>
										{intl.formatMessage({
											id: 'portal.process.report.button.processActionsReport',
										})}
									</span>
								</Button>
							</div>
							{employeeProgressIsFetching ? (
								<Loader />
							) : (
								<EmployeeProgress processList={employeeProcessList} />
							)}
						</TabPanel>
					)}
					{!!contactList.length && (
						<TabPanel>
							<Contacts contactList={contactList} />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</div>
	);
};

export default injectIntl(
	wire<PortalMobileWiredProps, PortalMobileProps>(
		['services.userService', 'services.onboardingProcessService'],
		PortalMobile
	)
);
