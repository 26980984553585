export default {
	'team-roles': {
		admin: 'Admin',
		manager: 'Manager',
		user: 'User',
	},
	error: {
		title: 'Nie masz dostępu do treści lub zawartość nie jest jeszcze dostępna!',
		button: 'Powrót do strony głównej',
		info: 'W przypadku problemów skontaktuj się ze swoim administratorem technicznym.',
	},
	'landing-page': {
		link: {
			'sign-up': 'Zarejestruj się',
			'sign-in': 'Zaloguj się',
		},
		copyright: 'Gamfi Spółka z o.o.',
		acceptation: 'Korzystanie z aplikacji oznacza akceptację',
		'terms-and-conditions': {
			'cookie-policy': 'Polityki Cookies',
			'privacy-policy': 'Polityki prywatności',
			'terms-and-conditions': 'Regulaminu',
		},
	},
	auth: {
		form: {
			error: {
				required: 'To pole jest wymagane.',
				'invalid-email': 'Podany adres e-mail jest nieprawidłowy.',
				'invalid-identifier': 'Podany identyfikator jest nieprawidłowy.',
				'user-not-exists': 'Podany użytkownik lub hasło jest nieprawidłowe.',
				'user-not-verified': 'Podany użytkownik nie jest zweryfikowany.',
				'user-inactive': 'Podany użytkownik jest nieaktywny.',
				'user-not-found': 'Podany użytkownik lub hasło jest nieprawidłowe.',
				'user-profile-not-active': 'Konto użytkownika jest nieaktywne.',
				'invalid-password-reset-token': 'Podany token jest nieprawidłowy.',
				'invalid-password': 'Podane hasło jest nieprawidłowe.',
				'passwords-differ': 'Podane hasło nie jest identyczne z poprzednim.',
				'invalid-input': 'Wprowadzone dane są błędne.',
				'user-already-registered': 'Podany użytkownik już jest zarejestrowany.',
				'required-agreements': 'Ta zgoda jest obowiązkowa.',
				'agreements-required': 'Zgody oznaczone gwiazdką są obowiązkowe.',
				'empty-password': 'Pole hasła jest obowiązkowe.',
				'password-minimal-length': 'Wymagana minimalna liczba znaków: {length}.',
				'password-minimal-lowercase-occurance': 'Wymagana minimalna liczba małych liter: {occurance}.',
				'password-minimal-uppercase-occurance': 'Wymagana minimalna liczba dużych liter: {occurance}.',
				'password-minimal-number-occurance': 'Wymagana minimalna liczba cyfr: {occurance}.',
				'password-minimal-special-occurance': 'Wymagana minimalna liczba znaków specjalnych: {occurance}.',
				'disabled-in-configuration': 'Integracja została wyłączona',
				'user-profile-does-not-match': 'Podane dane nie są zgodne z profilem użytkownika.',
				'email-domain-not-on-whitelist': 'Rejestracja konta nie jest możliwa w tej domenie.',
				'not-allowed': {
					'user-registration-not-complete': 'Użytkownik nie dokończył jeszcze rejestracji.',
					'user-not-verified': 'Podany użytkownik nie jest zweryfikowany.',
				},
				'one-of-two': 'Przynajmniej jedno z tych pól jest wymagane.',
				'resource-error': 'Wystąpił błąd podczas logowania.',
				email: {
					'invalid-domain': 'Niepoprawna nazwa domeny.',
				},
				phone: {
					'invalid-format': 'Niepoprawny format numeru telefonu.',
					'invalid-length': 'Niepoprawna długość numer telefonu.',
				},
			},
		},
		login: {
			navigate: {
				'not-account-yet': 'Nie masz jeszcze konta?',
				register: 'Zarejestruj się',
				'do-not-remember-data': 'Ustaw lub zresetuj hasło',
				'do-not-remember-identifier': 'Nie pamiętasz identyfikatora lub hasła?',
			},
			integration: {
				email: {
					title: 'Zaloguj się używając adresu e-mail',
					field: {
						email: {
							placeholder: 'Wpisz adres e-mail',
						},
						password: {
							placeholder: 'Wpisz hasło',
						},
					},
					button: {
						login: 'Zaloguj się',
						check: 'Zaloguj się',
					},
					status: {
						'reset-password': {
							title: 'Aktywowanie konta',
							message:
								'Link do ustawienia hasła został wysłany na podany adres e-mail. Z powodów bezpieczeństwa link jest ważny tylko przez 60 minut.',
						},
					},
				},
				'imported-id': {
					title: 'Zaloguj się używając adresu e-mail lub identyfikatora',
					field: {
						identifier: {
							placeholder: 'Wpisz adres e-mail lub identyfikator',
						},
						password: {
							placeholder: 'Wpisz hasło',
						},
					},
					button: {
						login: 'Zaloguj się',
						register: 'Zarejestruj się',
						check: 'Zaloguj się',
					},
					status: {
						'reset-password': {
							title: 'Resetowanie hasła',
							message:
								'Link do ustawienia hasła został wysłany na podany adres e-mail. Z powodów bezpieczeństwa link jest ważny tylko przez 60 minut.',
						},
					},
				},
				oauth: {
					facebook: 'Zaloguj się przez Facebooka',
					generic: 'Zaloguj się za pomocą zewnętrznego systemu',
					google: 'Zaloguj się przez Google',
					'linked-in': 'Zaloguj się przez LinkedIn',
					error: {
						title: 'Wystąpił nieoczekiwany błąd podczas logowania',
						type: {
							general: 'Logowanie przez OAuth zostało przerwane.',
							'user-already-registered': 'Użytkownik z podanym adresem e-mail już istnieje.',
							'invalid-one-time-token': 'Podany token jest nieprawidłowy.',
							'not-allowed': {
								'connect-profiles-before-authentication':
									'E-mail przypisany do konta integracji znajduje się już w naszej bazie. Zaloguj się do platformy używając adresu e-mail.',
							},
						},
					},
					return: 'Kliknij tutaj, aby powrócić do strony głównej.',
					loading: 'Trwa logowanie, proszę czekać...',
				},
			},
		},
		register: {
			navigate: {
				'already-have-account': 'Masz już konto?',
				login: 'Zaloguj się',
			},
			integration: {
				email: {
					title: 'Zarejestruj się używając adresu e-mail',
					field: {
						email: {
							placeholder: 'Wpisz adres e-mail',
						},
					},
					button: 'Zarejestruj się',
					status: {
						ok: {
							title: 'Twoje konto zostało zarejestrowane',
							message: 'Sprawdź swoją skrzynkę pocztową, znajdziesz tam e-mail z linkiem do aktywacji.',
						},
					},
				},
				'imported-id': {
					title: 'Proces rejestracji',
					field: {
						forename: {
							placeholder: 'Wpisz imię',
						},
						surname: {
							placeholder: 'Wpisz nazwisko',
						},
						email: {
							placeholder: 'Wpisz adres e-mail',
						},
						phone: {
							placeholder: 'Wpisz numer telefonu',
						},
					},
					button: {
						register: 'Zarejestruj się',
					},
					status: {
						'email-sent': {
							title: 'Twoje konto zostało zarejestrowane',
							message: 'Sprawdź swoją skrzynkę pocztową, znajdziesz tam e-mail z linkiem do aktywacji.',
						},
					},
				},
				oauth: {
					facebook: 'Zarejestruj się przez Facebooka',
					generic: 'Zarejestruj się za pomocą zewnętrznego systemu',
					google: 'Zarejestruj się przez Google',
					'linked-in': 'Zarejestruj się przez LinkedIn',
				},
				token: {
					title: 'Utwórz konto',
					submit: 'Zarejestruj się',
					error: {
						'no-token': 'Nieprawidłowy token',
					},
					status: {
						ok: {
							title: 'Twoje konto zostało zarejestrowane',
						},
					},
				},
			},
		},
		'reset-password': {
			navigate: {
				'remember-password': 'Przypomniałeś sobie hasło?',
				login: 'Zaloguj się',
			},
			integration: {
				email: {
					title: 'Ustaw lub zresetuj hasło',
					instruction: {
						text:
							'Wpisz swój {email}, na który wyślemy do Ciebie wiadomość e-mail z linkiem umożliwiającym ustawienie hasła.',
						email: 'adres e-mail',
					},
					field: {
						email: {
							placeholder: 'Wpisz adres e-mail',
						},
					},
					button: 'Wyślij',
					status: {
						ok: {
							title: 'Resetowanie hasła',
							message:
								'Link do ustawienia hasła został wysłany na podany adres e-mail. Z powodów bezpieczeństwa link jest ważny tylko przez 60 minut.',
						},
					},
				},
				'email-annoying': {
					status: {
						ok: {
							title: 'Resetowanie hasła',
							message:
								'Jeżeli podany adres e-mail jest w bazie użytkowników to link do ustawienia hasła został wysłany.',
						},
					},
				},
				'imported-id': {
					title: 'Resetowanie hasła',
					instruction: {
						text:
							'Wpisz swój {identifier}, który był przez Ciebie użyty do rejestracji. Wyślemy do Ciebie wiadomość e-mail z łączem lub kod autoryzacyjny SMSem umożliwiającym zresetowanie hasła.',
						identifier: 'adres e-mail lub identyfikator',
					},
					field: {
						identifier: {
							placeholder: 'Wpisz swój adres e-mail lub identyfikator',
						},
					},
					button: 'Wyślij',
					status: {
						'email-sent': {
							title: 'Resetowanie hasła',
							message:
								'Link do ustawienia hasła został wysłany na podany adres e-mail. Z powodów bezpieczeństwa link jest ważny tylko przez 60 minut.',
						},
					},
				},
			},
			action: {
				'verify-token': {
					title: 'Nastąpił problem z otrzymanym linkiem',
					message: 'Twój link stracił ważność lub jest nieprawidłowy',
					'reset-link': '{link} a następnie podaj swój adres email.',
					'reset-link-text': 'Kliknij tutaj',
				},
				'set-password': {
					title: {
						reset: 'Ustaw swoje nowe hasło',
						activate: 'Aktywuj konto ustawiając swoje hasło',
					},
					field: {
						password: {
							placeholder: 'Wpisz hasło',
						},
						'confirm-password': {
							placeholder: 'Powtórz hasło',
						},
					},
					button: {
						reset: 'Ustaw nowe hasło',
						activate: 'Aktywuj konto',
					},
				},
				'authorize-code': {
					title: {
						reset: 'Wprowadź kod autoryzacyjny',
					},
					field: {
						'authorization-code': {
							placeholder: 'wpisz kod autoryzacyjny',
						},
					},
				},
			},
		},
	},
	'achievement-list': {
		title: 'Osiągnięcia',
		subtitle: 'opis',
		'all-tab': 'Wszystkie',
		'section-title': {
			progress: 'Twój postęp',
			'gain-info': 'Za zdobycie tego osiągnięcia otrzymasz:',
		},
		content: {
			'progress-description': 'Z dostępnych osiągnięć w kategorii "Wszystkie" udało Ci się zdobyć:',
			requirements: 'Wymagania',
		},
		error: {
			empty: 'Lista osiągnięć jest pusta.',
		},
	},
	'achievement-details': {
		'section-title': {
			'achievement-description': 'Opis osiągnięcia:',
			requirements: 'Wymagania zdobycia osiągnięcia:',
		},
	},
	faq: {
		title: 'FAQ',
		subtitle: 'Opis',
		'all-tab': 'Wszystkie',
		content: {
			category: 'Kategoria:',
		},
		error: {
			empty: 'FAQ jest puste.',
		},
	},
	'mission-list': {
		title: 'Misje',
		content: {
			'tasks-execution': 'Zadań {completed} z {all}',
			'earnings-information': {
				mission: 'Za wykonanie wszystkich zadań otrzymasz:',
				task: 'Za wykonanie tego zadania otrzymasz:',
			},
		},
		button: {
			do: 'Wykonaj',
		},
		error: {
			empty: 'Brak misji.',
		},
	},
	'news-details': {
		subtitle: 'Dodano: ',
		'section-title': {
			comments: 'Komentarze',
		},
	},
	'news-list': {
		title: 'Newsy',
		subtitle: 'opis',
		content: {
			added: 'Dodano',
		},
		error: {
			empty: 'Lista newsów jest pusta.',
		},
	},
	'notification-list': {
		title: 'Powiadomienia',
		subtitle: 'opis',
		'all-tab': 'Wszystkie',
		error: {
			empty: 'Brak powiadomień.',
		},
	},
	'prize-list': {
		title: 'Geszynki',
		subtitle: 'opis',
		'all-tab': 'Wszystkie',
		'section-title': {
			'items-in-your-range': 'Nagrody w Twoim zasięgu',
		},
		button: {
			'buy-now': 'Zamów',
			'check-out': 'Zobacz',
			soon: 'Wkrótce',
		},
		content: {
			amount: 'Dostępne',
			count: '{remaining} z {start}',
			'availability-items': 'Dostępnych sztuk',
			'sold-out': 'Niedostępne',
			'from-date': 'Od',
			'missing-points': 'Brakuje Ci:',
			price: 'Cena',
		},
		error: {
			empty: 'Brak nagród w kategorii.',
		},
	},
	'prize-details': {
		'section-title': {
			'prize-description': 'Opis',
			price: 'Cena:',
			'available-currently': 'Obecnie dostępnych',
		},
		button: {
			'buy-now': 'Zamów',
			confirm: 'Potwierdzam',
			resign: 'Zrezygnuj',
			soon: 'Wkrótce',
		},
		content: {
			'not-available': 'Nagroda niedostępna!',
			missing: 'Brakuje Ci:',
			calendar: {
				monday: 'p',
				tuesday: 'w',
				wednesday: 'ś',
				thursday: 'c',
				friday: 'p',
				saturday: 's',
				sunday: 'n',
				'info-cloud': {
					'available-on-day': 'Dostępnych nagród tego dnia:',
					'not-available': 'Nagroda niedostępna w tym dniu.',
				},
			},
			'buy-item': {
				'confirm-purchase': 'Zamawiam',
				cancel: 'Rezygnuje',
				close: 'Zamknij',
				ordered: 'Zamówienie zostało złożone! Dziękujemy!',
				error: 'Wystąpił błąd podczas zamawiania',
				address: {
					create: 'Utwórz nowy adres...',
				},
			},
			'sold-out': 'Ni mo terozki gyszynku',
		},
	},
	ranking: {
		title: 'Ranking',
		subtitle: 'opis',
		content: {
			'your-place': {
				point__user: 'Twoja pozycja',
				user_imported: 'Twoja pozycja',
				commitment__user: 'Twoja pozycja',
				point__team: 'Pozycja Twojego zespołu',
				team_imported: 'Pozycja Twojego zespołu',
				point__container: '{rankingName} - Twoja pozycja',
				container_imported: '{rankingName} - Twoja pozycja',
			},
			button: {
				'log-in': 'Zaloguj się',
			},
		},
		error: {
			empty: 'Ranking jest pusty',
		},
	},
	'task-details': {
		'collection-mission': {
			title: '{name}',
			button: {
				repeat: 'Powtórz',
				do: 'Wykonaj',
			},
			header: {
				'your-progress': 'Twój postęp',
				'progress-status': '{completed} z {all}',
			},
			content: {
				'gain-for-complete-mission': 'Za wykonanie wszystkich zadań otrzymasz:',
				'gain-for-complete-task': 'Za wykonanie tego zadania otrzymasz:',
				'new-tasks': `{
					newTasks,
					plural,
					one {Nowe zadanie}
					few {Nowe zadania}
					many {Nowych zadań}
					zero {Nowych zadań}
				}`,
				'done-tasks': `{
					doneTasks,
					plural,
					one {Wykonane zadanie}
					few {Wykonane zadania}
					many {Wykonanych zadań}
					zero {Wykonanych zadań}
				}`,
			},
		},
		graph: {
			content: {
				progress: 'Twój postęp w misji',
				progressStatus: '{completed} z {all}',
			},
		},
		mockup: {
			button: {
				leave: 'Wylyź',
				continue: 'Kontynuuj',
			},
			content: {
				'answer-status': {
					'wrong-answer': 'Źle odpowiedziałeś na pytanie - spróbuj ponownie',
					'correct-answer': 'Prawidłowo wykonane zadanie',
					'try-again-in': 'Spróbuj ponownie za: ',
					gaining: 'Otrzymujesz',
				},
			},
		},
		'complete-phrase-question': {
			content: {
				'task-description': 'Twoim zadaniem jest uzupełnienie brakujących słów w polach.',
			},
		},
		declaration: {
			content: {
				'task-description': 'Twoim zadaniem jest zadeklarowanie wykonania poniższych czynności.',
			},
		},
		'guess-phrase-question': {
			input: {
				'enter-answer': 'Tutaj wpisz odpowiedź',
			},
			content: {
				'task-description': 'Twoim zadaniem jest wpisanie poprawnej odpowiedzi.',
			},
		},
		'retype-phrase-question': {
			content: {
				'task-description': 'Twoim zadaniem jest przepisanie wskazanych słów.',
			},
		},
		'visit-link': {
			content: {
				'instruction-after': 'Zadanie polegało na przejrzeniu strony:',
				'instruction-before':
					'Twoim zadaniem jest przejrzenie treści. Kliknij "Wykonaj", aby zaliczyć zadanie.',
			},
		},
		document: {
			button: {
				'download-pdf': 'Pobierz PDF',
			},
		},
		'true-false': {
			button: {
				'true-claim': 'Prawda',
				'false-claim': 'Fałsz',
			},
		},
		join: {
			button: {
				join: 'Dołącz',
			},
			'is-a-member-already': {
				description: 'Dokonałeś/aś już wcześniej wyboru:',
			},
			'is-anonymous': {
				description: 'Nie możesz wykonać tego zadania, ponieważ:',
				title: 'nie jesteś zalogowany!',
				'click-button': 'Kliknij przycisk poniżej, by móc w pełni uczestniczyć w grze.',
				'button-text': 'Zarejestruj się',
			},
			'join-task-crashed': {
				description: 'Wystąpił błąd systemu!',
				title: 'Skontaktuj się z administratorem',
			},
			'team-join-view': {
				title: 'Wybierz poniżej',
			},
			'user-container-join-view': {
				title: 'Wybierz poniżej',
			},
		},
		'user-profile-fields': {
			instruction:
				'Twoim zadaniem jest uzupełnienie wszystkich pozycji. Następnie kliknij "Potwierdź", aby zaliczyć zadanie.',
		},
		common: {
			button: {
				continue: 'Kontynuuj',
				check: 'Sprawdź',
				do: 'Wykonaj',
				'do-again': 'Wykonaj ponownie',
				leave: 'Wylyź',
				confirm: 'Potwierdź',
				help: 'Pomoc',
			},
			content: {
				'answer-status': {
					'remaining-static': 'Pozostało: ',
					remaining: `Do ukończenia zadania {
						seconds,
						plural,
						one {pozostała}
						few {pozostały}
						many {pozostało}
					}`,
					'remaining-seconds': `{
						seconds,
						plural,
						one {sekunda}
						few {sekundy}
						many {sekund}
					}`,
					'already-completed': 'To zadanie już zostało wykonane!',
					'wrong-answer-try-again': 'Źle odpowiedziałeś na pytanie - spróbuj ponownie',
					'wrong-answer': 'Niestety odpowiedź błędna',
					'correct-answer': 'Prawidłowo wykonane zadanie',
					'try-again-in': 'Spróbuj ponownie za: ',
					gaining: 'Otrzymujesz',
					crashed: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie za chwilę.',
					awaiting: 'Zadanie zostanie zaliczone w momencie akceptacji przez managera zespołu.',
					rejected: 'Zadanie nie zostało zaliczone.',
					'rejected-reason': 'Zadanie nie zostało zaliczone. {reason}',
				},
				repetition: {
					'repetition-time-last': 'Zadanie do ponownego wykonania zostanie odblokowane za',
					'repetition-approval': 'Możesz wykonać nowe zadanie',
					'gained-points': 'Zdobytych punktów',
					'completion-time': 'Czas wykonania',
					completion: 'Wykonanie {times}',
					days: `{
						days,
						plural,
						one {dzień}
						few {dni}
						many {dni}
					}`,
				},
			},
		},
	},
	'user-profile': {
		field: {
			mapped: {
				forename: 'Imię',
				surname: 'Nazwisko',
				nickname: 'Nickname',
				description: 'Opis',
				email: 'E-mail',
				phone: 'Telefon',
			},
			'click-here': 'Kliknij tutaj',
		},
	},
	'user-details': {
		'notification-tab': 'Powiadomienia',
		button: {
			'mobile-mode': {
				edit: 'Zapisz',
				preview: 'Edytuj',
			},
			mode: {
				edit: 'Zapisz zmiany',
				preview: 'Edytuj profil',
			},
			edit: 'Edytuj',
			'choose-photo': 'Wybierz obrazek',
		},
		content: {
			'user-data': {
				position: 'Pozycja:',
				'last-activity': 'Ostatnia aktywność',
			},
			level: 'Poziom ',
			'remaining-signs': 'Pozostało znaków: ',
			'edit-in-progress': 'Edytujesz profil',
			editField: {
				nickname: 'Nickname',
				description: 'Opis',
			},
		},
		tabs: {
			informations: 'Informacje',
			notifications: 'Powiadomienia',
		},
		agreements: {
			title: 'Twoje zgody',
			gdpr: {
				title: 'RODO',
				warning:
					'Jeśli wycofasz zgodę, usuniemy Twoje konto w aplikacji oraz skasujemy Twoje dane osobowe, zdobyte punkty i postęp w realizacji zadań. Usuniętego konta nie można przywrócić. Możesz je jednak założyć ponownie.',
			},
			required: 'Wymagane',
			optional: 'Opcjonalne',
			confirm: 'Potwierdzam zmiany zgody!',
			saveButton: 'Zapisz',
			'accept-requirements': 'Musisz potwierdzić zmiany',
			'your-agreements': 'Twoje zgody',
		},
	},
	'team-details': {
		button: {
			'mobile-mode': {
				edit: 'Zapisz',
				preview: 'Edytuj',
			},
			mode: {
				edit: 'Zapisz zmiany',
				preview: 'Edytuj profil',
			},
			edit: 'Edytuj',
		},
		content: {
			'remaining-signs': 'Pozostało znaków: ',
			'edit-in-progress': 'Edytujesz profil',
		},
		tabs: {
			members: 'Członkowie',
			tasks: 'Zadania',
			settings: 'Ustawienia',
		},
		tasks: {
			status: {
				awaiting: 'wykonał(a) zadanie {task}. Czy to prawda? Zdecyduj!{br}w dniu {date}',
				accepted: 'wykonał(a) zadanie {task}{br}w dniu {date}',
				rejected: 'nie wykonał(a) poprawnie zadania {task}{br}w dniu {date}',
			},
			button: {
				confirm: 'Potwierdź',
				reject: 'Odrzuć',
			},
			'rejection-modal': {
				title: 'Odrzucenie zadania',
				'reason-placeholder': 'Podaj powód odrzucenia (opcjonalnie)',
				button: 'Odrzuć',
			},
		},
	},
	'create-submission': {
		return: {
			or: 'Kliknij ',
			link: 'tutaj',
			rest: ', aby zmienić typ zgłoszenia!',
		},
		form: {
			error: {
				'too-short': 'Minimalna liczba znaków to: {value}',
				'too-long': 'Maksymalna liczba znaków to: {value}',
				required: 'To pole jest wymagane!',
			},
		},
		confirmation: {
			title: 'Zgłoszenie dodane',
			button: 'Zakończ',
			content: {
				'line-1': 'Twoje zgłoszenie zostało dodane i znajduje się w moderacji.',
				'line-2': 'Poinformujemy Cię o zmianie statusu.',
			},
		},
		error: {
			title: 'Wystąpił nieoczekiwany błąd',
			content: 'Spróbuj jeszcze raz za kilka chwil.',
			button: 'Zakończ',
		},
		'image-submission': {
			title: 'Twoje zgłoszenie',
			form: {
				title: {
					placeholder: 'Wprowdź tytuł',
				},
				file: 'naciśnij tutaj, aby dodać zdjęcie',
				button: 'Dodaj',
			},
		},
		'submission-type-selection': {
			title: 'Weź udział w wyzwaniu',
			subtitle: 'Wybierz typ zgłoszenia:',
			type: {
				text: 'Dodaj tekst',
				image: 'Dodaj obrazek',
			},
		},
		'text-submission': {
			title: 'Twoje zgłoszenie',
			form: {
				title: {
					placeholder: 'Wprowdź tytuł',
				},
				text: {
					placeholder: 'Wprowadź treść',
				},
				button: 'Dodaj',
			},
		},
	},
	'challenge-list': {
		title: 'Wyzwania',
		subtitle: 'opis',
		loading: 'Trwa ładowanie listy wyzwań...',
		tabs: {
			open: 'Trwające',
			future: 'Nadchodzące',
			finished: 'Zakończone',
			all: 'Wszystkie',
		},
		content: {
			'empty-list': 'Brak wyzwań. Wróć niebawem! ;-)',
			'challenge-status': {
				notStarted: 'Do rozpoczęcia pozostało: {remainingTime}',
				inProgress: 'W trakcie:',
				beforeResolve: 'Oczekiwanie na wyniki',
				finished: 'Zakończone',
			},
			'reverse-status': {
				notStarted: 'Startujemy za',
				inProgress: 'Pozostało',
				beforeResolve: 'Ogł. wyników za',
			},
			earnings: 'Do zdobycia',
		},
		button: {
			takePart: 'Weź udział',
		},
		error: {
			empty: 'Lista wyzwań jest pusta.',
		},
		type: {
			user: 'Indywidualne',
			team: 'Drużynowe',
			'container-team': 'Po strukturze - drużynowe',
			'container-user': 'Po strukturze - indywidualne',
		},
	},
	'challenge-details': {
		creative: {
			type: {
				image: 'Obrazek',
				text: 'Tekst',
				video: 'Film',
				gif: 'Gif',
			},
			'section-title': {
				'challenge-info': 'Szczegóły:',
			},
			button: {
				'take-part': 'Weź udział',
				'add-submission-in': 'Dodaj kolejne zgłoszenie za',
				'limit-reached': 'Twój limit zgłoszeń został osiągnięty',
				'only-for-manager': 'Tylko dla menedżera zespołu',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Typ wyzwania',
					'creative-type': 'Kreatywne',
					'submission-type': 'Rodzaj zgłoszeń',
					'submission-types': {
						video: 'Film',
						image: 'Obrazek',
						text: 'Tekst',
						gif: 'Gif',
					},
					'participation-mode': 'Typ uczestnictwa',
					'submissions-limit': 'Limit zgłoszeń',
					'points-for-added-submission': 'Punkty za dodane zgłoszenie',
					'points-for-accepted-submission': 'Punkty za zaakceptowane zgłoszenie',
				},
				'not-selected-winners': 'Wyzwanie nie zostało jeszcze rozwiązane',
				remains: 'Pozostało',
			},
			'menu-tabs': {
				details: 'Szczegóły',
				'my-submissions': 'Moje',
				'recently-added-submissions': 'Ostatnio dodane',
				ranking: 'Ranking',
				prizes: 'Nagrody',
				winners: 'Zwycięzcy',
			},
			'submission-list': {
				error: {
					empty: {
						user: 'Jeszcze nic nie dodałeś/aś. Zmień to, kliknij w "Weź udział"! ;-)',
						common: 'Tutaj pojawią się zaakceptowane zgłoszenia.',
					},
					'publication-at': {
						'line-1': 'Dodane zgłoszenia zostaną opublikowane w dniu {date}.',
						'line-2': 'Nie zostawaj w tyle, dodaj swoje zgłoszenie już teraz - kliknij "Weź udział"!',
					},
				},
				content: {
					'submission-status': {
						rejected: 'Odrzucone',
						accepted: 'Zaakceptowane',
						new: 'Oczekuje na moderację',
						'reject-reason': 'Powód odrzucenia: {reason}',
					},
					remains: 'Pozostało: {remaining-time}',
					removed: 'Użytkownik zrezygnował z gry i wycofał zgodę na publikację treści',
				},
				button: {
					'remove-submission': 'Usuń to zgłoszenie',
				},
			},
			popup: {
				'choose-submission-type': {
					title: 'Weź udział w wyzwaniu',
					subtitle: 'Dostępne typy zgłoszeń:',
					button: {
						'add-gif': 'Dodaj gif',
						'add-video': 'Dodaj video',
						'add-text': 'Dodaj tekst',
						'add-image': 'Dodaj obrazek',
						'add-link': 'Dodaj link',
					},
				},
				'add-submission-confirmation': {
					title: 'Zgłoszenie dodane!',
					button: {
						close: 'Zakończ',
					},
					content: {
						'already-added': 'Twoje zgłoszenie zostało dodane i znajduje się w moderacji.',
						'status-change-info': 'Poinformujemy Cię o zmianie statusu.',
					},
				},
				'link-submission': {
					title: 'Dodaj link w temacie wyzwania',
					button: {
						return: 'Wróć',
					},
					content: {
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
				},
				'gif-submission': {
					title: 'Dodaj gif w temacie wyzwania',
					subtitle: 'Zacznij szukać gifów',
					button: {
						'press-to-add': 'Naciśnij tutaj aby dodać gif',
						return: 'Wróć',
					},
					content: {
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
				},
				'video-submission': {
					title: 'Dodaj film w temacie wyzwania',
					button: {
						'press-to-add': 'Naciśnij tutaj aby dodać film',
						return: 'Wróć',
						continue: 'Kontynuuj',
					},
					content: {
						'or-paste-video-link': 'albo wklej link do filmiku',
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
				},
				'image-submission': {
					title: 'Dodaj zdjyńcie w temacie wyzwania',
					button: {
						'press-to-add': 'Naciśnij tutaj aby dodać zdjyńcie',
						return: 'Wróć',
						continue: 'Kontynuuj',
					},
					content: {
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
				},
				'text-submission': {
					title: 'Napisz tekst w temacie wyzwania',
					button: {
						continue: 'Kontynuuj',
						return: 'Wróć',
						or: 'lub',
					},
					content: {
						'remaining-signs': 'Pozostało znaków: ',
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
					error: 'Aby kontynuować uzupełnij treść wyzwania.',
				},
				'submission-summary': {
					title: 'Podsumowanie',
					subtitle: 'Nazwij swoje zgłoszenie w wyzwaniu',
					button: {
						add: 'Ok, dodaję',
						return: 'Wróć',
					},
					content: {
						'remaining-signs': 'Pozostało znaków: ',
						'return-to-choose-submission': 'lub {return} aby zmienić typ zgłoszenia!',
					},
					error: 'Aby kontynuować uzupełnij tytuł zgłoszenia.',
				},
				'submission-details': {
					button: {
						'add-comment': 'Dodaj komentarz',
						answer: 'Odpowiedz',
					},
					'section-title': {
						comments: 'Komentarze',
						'add-comment': 'Dodaj komentarz',
					},
					content: {
						wrote: 'Napisał(a)',
						'remaining-signs': 'Pozostało znaków',
						status: 'Status: ',
						'current-status': {
							new: 'Czeka na moderację',
							accepted: 'Zaakceptowano',
							rejected: 'Odrzucono',
						},
					},
				},
			},
		},
		kpi: {
			'section-title': {
				'challenge-info': 'Szczegóły',
			},
			button: {
				'take-part': 'Weź udział',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Typ wyzwania',
					'kpi-type': 'KPI',
					'submission-type': 'Rodzaj zgłoszeń',
					'participation-mode': 'Typ uczestnictwa',
					'submissions-limit': 'Limit zgłoszeń',
					'last-import-date': 'Ostatnia aktualizacja',
				},
				remains: 'Pozostało: {remaining-time}',
			},
			'menu-tabs': {
				ranking: 'Ranking',
				details: 'Szczegóły',
				prizes: 'Nagrody',
			},
			'submission-details': {
				author: 'Autor:',
				date: 'Data:',
			},
		},
		target: {
			'section-title': {
				'challenge-info': 'Szczegóły:',
			},
			content: {
				'challenge-info': {
					'challenge-type': 'Typ wyzwania',
					'creative-type': 'Cel',
					'participation-mode': 'Typ uczestnictwa',
					'submission-count': 'Zrealizowano',
					'submissions-limit': 'Limit',
					'points-for-accepted-submission': 'Punkty za zgłoszenie',
					completed: 'Zrealizowano:',
				},
			},
			'menu-tabs': {
				details: 'Szczegóły',
			},
		},
		common: {
			ranked: {
				place: 'Miejsce {placeFrom}',
				'place-range': 'Miejsca od {placeFrom} do {placeTo}',
			},
			'no-prize-set': 'Brak nagród.',
		},
	},
	cafeteria: {
		title: 'Kafeteria z nagrodami',
		error: {
			empty: 'Kafeteria z nagrodami jest pusta.',
			'internal-server-error': 'Wystąpił błąd podczas wyświetlania kafeterii.',
			'access-denied': 'Nie masz dostępu do kafeterii.',
			'disabled-in-configuration': 'Kafeteria z nagrodami jest wyłączona.',
			'no-budget': 'Niestety, nagroda nie jest już dostępna.',
			'too-few-points': 'Niewystarczająca liczba punktów. Zakup niemożliwy.',
			'invalid-input': 'Formularz zawiera błędy.',
			'item-sold-out': 'Nagroda wyprzedana.',
		},
		filter: {
			price: 'Cena',
			afford: 'Pokaż nagrody, na które mnie stać.',
			from: 'od',
			to: 'do',
		},
		item: {
			'buy-now': 'Kup teraz',
			'not-available': 'Niedostępna',
			missing: 'Brakuje Ci',
			price: 'Cena',
		},
	},
	notification: {
		static: {
			messages: {
				submission: {
					new: 'Dodałeś zgłoszenie "{submissionTitle}" do wyzwania "{challengeTitle}"',
					rejected:
						'Twoje zgłoszenie "{submissionTitle}" w wyzwaniu "{challengeTitle}" zostało odrzucone z powodu: {reason}',
					'rejected-without-reason':
						'Twoje zgłoszenie "{submissionTitle}" w wyzwaniu "{challengeTitle}" zostało odrzucone',
					accepted:
						'Twoje zgłoszenie "{submissionTitle}" w wyzwaniu "{challengeTitle}" zostało zaakceptowane',
					awardedPlace:
						'Zdobyłeś nagrodę za osiągnięcie miejsca {awardedPlace} w wyzwaniu "{challengeTitle}".',
					addedSubmission: 'Dodano zgłoszenie "{submissionTitle}" w wyzwaniu "{challengeTitle}".',
				},
				'declaration-task': {
					accepted: 'Twoje zgłoszenie w zadaniu "{taskName}" zostało zaakceptowane',
					'rejected-reason': 'Twoje zgłoszenie w zadaniu "{taskName}" zostało odrzucone',
					rejected: 'Twoje zgłoszenie w zadaniu "{taskName}" zostało odrzucone',
				},
				email: {
					'remember-pass': 'Wysłanie e-maila: Resetowanie hasła',
					'reset-pass': 'Wysłanie e-maila: Resetowanie hasła',
					registration: 'Wysłanie e-maila: Rejestracja konta',
					'submission-rejected': 'Wysłanie e-maila: zgłoszenie zostało odrzucone',
					'submission-accepted': 'Wysłanie e-maila: zgłoszenie zostało zaakceptowane',
				},
				bonusTransaction: 'Zmiana stanu punktów',
				task: '{name}',
				item: '{name}',
				levelUp: {
					withName: 'Poziom {levelNumber} - {name}',
					withoutName: 'Poziom {levelNumber}',
				},
				achievement: '{name}',
				challenge: {
					target: '{name}',
				},
			},
			dates: {
				task: {
					'task-completed': 'Zadanie wykonano: {date}',
					'mission-completed': 'Misję wykonano: {date}',
					'task-progress': 'Osiągnięto dalszy postęp: {date}',
				},
				achievement: 'Odznaczenie zdobyto: {date}',
				item: 'Przedmiot kupiono: {date}',
				email: 'E-mail otrzymano: {date}',
				submission: {
					new: 'Zgłoszenie dodano: {date}',
					accepted: 'Zgłoszenie zaakceptowano: {date}',
					rejected: 'Zgłoszenie odrzucono: {date}',
					awardedPlace: 'Miejsce przyznano: {date}',
				},
				'declaration-task': {
					accepted: 'Zgłoszenie zaakceptowano: {date}',
					rejected: 'Zgłoszenie odrzucono: {date}',
				},
				bonusTransaction: 'Punkty przeliczono: {date}',
				challenge: {
					target: 'Wykonano: {date}',
				},
			},
			'null-states': {
				task: '[Zadanie usunięte]',
				achievement: '[Osiągnięcie usunięte]',
				item: '[Przedmiot usunięty]',
				submission: '[Usunięte]',
				awardedPlace: '[Usunięte]',
			},
		},
		'real-time': {
			achievement: {
				title: 'Gratulacje!',
				subtitle: 'Zdobyłeś osiągnięcie',
				button: {
					default: 'Odbierz',
					'without-earnings': 'Kontynuuj',
				},
				'achievement-unlocked': 'Osiągnięcie odblokowane!',
				'earn-achievement': 'Zdobywasz osiągnięcie',
				earn: 'Zdobywasz',
			},
			challengeSubmissionStateChanged: {
				title: 'Gratulacje!',
				subtitle: 'Zmienił się status Twojego zgłoszenia w wyzwaniu',
				button: {
					default: 'Kontynuuj',
					'without-earnings': 'Kontynuuj',
				},
				earn: 'Zdobywasz',
			},
			declarationTaskAcceptanceChanged: {
				title: 'Gratulacje!',
				subtitle: 'Zmienił się status Twojego zadania',
				button: {
					default: 'Kontynuuj',
					task: 'Sprawdź dlaczego',
				},
				earn: 'Zdobywasz',
			},
			task: {
				title: 'Gratulacje!',
				subtitle: 'Wykonałeś zadanie',
				button: {
					default: 'Odbierz punkty',
					'without-earnings': 'Kontynuuj',
					nextTask: 'Kontynuuj',
				},
				congratulations: 'Gratulacje!',
				'for-execution': 'Za wykonanie zadania otrzymujesz:',
			},
			levelUp: {
				title: 'Gratulacje!',
				subtitle: 'Osiągnąłeś nowy poziom',
				button: {
					default: 'Kontynuuj',
					'without-earnings': 'Kontynuuj',
				},
				earn: 'Zdobywasz',
			},
			mission: {
				title: 'Gratulacje!',
				subtitle: 'Misja zaliczona',
				button: {
					default: 'Odbierz punkty',
					'without-earnings': 'Kontynuuj',
					nextTask: 'Kontynuuj',
				},
				congratulations: 'Gratulacje!',
				'for-execution': 'Za wykonanie misji otrzymujesz:',
			},
		},
	},
	currency: {
		bucks: `{
			bucks,
			plural,
			one {bucks}
			few {bucksy}
			many {bucksów}
		}`,
		exp: `{
			exp,
			plural,
			one {punkt}
			few {punkty}
			many {punktów}
		} doświadczenia`,
	},
	graph: {
		tooltip: {
			mission: {
				tasks: 'Zadań',
				progress: '{completed} z {all}',
			},
			button: {
				repeat: 'Nazot',
				execute: 'Wykonaj',
			},
		},
	},
	loader: {
		text: ['Wczytywanie ustawień użytkownika...'],
		loading: {
			settings: 'Pobieranie aplikacji...',
		},
	},
	time: {
		days: `{
			time,
			plural,
			one {# dzień}
			few {# dni}
			many {# dni}
		}`,
		hours: `{
			time,
			plural,
			one {# godzina}
			few {# godziny}
			many {# godzin}
		}`,
		minutes: `{
			time,
			plural,
			one {# minuta}
			few {# minuty}
			many {# minut}
		}`,
		seconds: `{
			time,
			plural,
			one {# sekunda}
			few {# sekundy}
			many {# sekund}
		}`,
	},
	markdown: {
		'show-more': '(pokaż więcej)',
		'show-less': '(zwiń)',
	},
	'terms-and-conditions': {
		'terms-and-conditions': 'Regulamin',
		'privacy-policy': 'Polityka prywatności',
		'cookie-policy': 'Polityka Cookies',
	},
	'contact-list': {
		title: 'Kontakty',
		search: {
			placeholder: 'Szukaj...',
		},
		tab: {
			all: 'Wszyscy',
			active: 'Aktywni',
		},
		error: {
			empty: 'Brak wyników.',
			'internal-server-error': 'Wystąpił błąd podczas wyświetlania książki kontaktowej.',
			'access-denied': 'Nie masz dostępu do książki kontaktowej.',
			'disabled-in-configuration': 'Książka kontaktowa jest wyłączona.',
		},
		pagination: {
			of: 'z',
		},
	},
	// clear translations:
	app: {
		installation: {
			title: 'Zainstaluj aplikację na urządzeniu',
			subtitle: 'Dodaj appkę na ekran główny,{separator}aby mieć do niej szybki i łatwy dostęp.',
			button: 'Kliknij, aby zainstalować',
		},
	},
	// reusable components translations:
	common: {
		'go-back': 'Nazot',
		form: {
			block: {
				fileUpload: {
					placeholder: 'Wybierz plik...',
					chooseButton: 'Wybierz plik',
					deleteButton: 'Usuń',
					processing: 'Przetwarzanie...',
					addAnotherFile: 'Dodaj kolejny plik',
				},
				dropdownDefault: 'Wybierz...',
			},
		},
		view: {
			list: {
				search: {
					placeholder: 'Szukaj...',
				},
			},
		},
	},
	component: {
		form: {
			'setting-field': {
				'click-here': 'Kliknij tutaj',
				'click-here-to-set': 'Kliknij tutaj, aby ustawić...',
			},
		},
	},
	form: {
		error: {
			required: 'Pole jest wymagane.',
			'check-required': 'Deklaracja jest obowiązkowa.',
			'too-short': 'Za mało znaków (min. {min} znaki).',
			'too-long': 'Za dużo znaków (max. {max} znaki).',
			'invalid-email': 'Adres e-mail nieprawidłowy.',
			'invalid-url': 'Adres URL nieprawidłowy.',
			'invalid-postal-code': 'Kod pocztowy nieprawidłowy.',
			'invalid-mobile-phone': 'Numer telefonu nieprawidlowy.',
			'min-elements': 'Za mało odpowiedzi (min. {min}).',
		},
	},
	portal: {
		title: 'Cześć {name}, oto Twój plan',
		process: {
			placeholder: 'Wow, a więc to tak wygląda tablica bez zadań. Super!',
			title: {
				duration: `Do zakończenia {
						duration,
						plural,
						one {pozostał: <span class="processList__text--highlighted">#&nbsp;dzień</span>}
						few {pozostało: <span class="processList__text--highlighted">#&nbsp;dni</span>}
						many {pozostało: <span class="processList__text--highlighted">#&nbsp;dni</span>}
					}.&nbsp;`,
				overdue:
					'<span class="processList__text--highlighted">Już po terminie! Wykonaj wszystkie zadania!</span>&nbsp;',
				'overdue-for-manager':
					'<span class="processList__text--highlighted">Termin zakończenia procesu już minął.</span>&nbsp;',
				date: {
					'first-day': `Do pierwszego dnia w pracy {
						time,
						plural,
						one {pozostał: <span class="processList__text--highlighted">#&nbsp;dzień</span>}
						few {pozostało: <span class="processList__text--highlighted">#&nbsp;dni</span>}
						many {pozostało: <span class="processList__text--highlighted">#&nbsp;dni</span>}
					}.`,
					'key-date': `Do rozpoczęcia procesu {
						time,
						plural,
						one {pozostał: <span class="processList__text--highlighted"># dzień</span>}
						few {pozostało: <span class="processList__text--highlighted"># dni</span>}
						many {pozostało: <span class="processList__text--highlighted"># dni</span>}
					}.`,
					'last-day': `Do ostatniego dnia w pracy {
						time,
						plural,
						one {pozostał: <span class="processList__text--highlighted"># dzień</span>}
						few {pozostało: <span class="processList__text--highlighted"># dni</span>}
						many {pozostało: <span class="processList__text--highlighted"># dni</span>}
					}.`,
				},
			},
			report: {
				downloadReport: 'Pobierz raport',
				button: {
					processReport: 'Procesy',
					processActionsReport: 'Akcje',
				},
			},
			filter: 'Filtruj',
			'filter-all': 'Wszystkie',
		},
		action: {
			type: {
				task: 'Zadanie',
				mission: 'Szkolenie',
				form: 'Formularz',
			},
			deadline: {
				general: '{time} na {type}',
				overdue: 'Przeterminowane: {time}',
				'solidsnake-task': 'wykonanie',
				'solidsnake-mission': 'wykonanie',
				'solidsnake-form': 'wypełnienie',
				form: 'wypełnienie',
				story: 'wykonanie',
			},
			placeholder: {
				messages: {
					1: 'Na dziś to wszystko, lista zadań wyczyszczona!',
					2: 'Houston, nie ma więcej zadań! Kończymy na dziś!',
					3: 'Nowe zadania pojawią się już wkrótce, po prostu płyń dalej!',
					4: 'Jeśli zadania to chmury, to dziś zapowiada się słonecznie! Korzystaj!',
					5: 'Abrakadabra! I wszystkie zadania znikają! Nie martw się, będzie ich więcej.',
					6: 'Na razie nie ma tu już więcej zadań. Ale mamy fajnego kaktusa. No zobacz jaki jest fajny!',
					7: 'Nie ma sensu kopać głębiej, żadnych zadań tu już nie ma. Na razie.',
				},
				nextAction: 'Spodziewaj się kolejnych zadań: <span class="color-blue font-weight-normal">{date}</span>',
				nextActionUnknownDate: 'Spodziewaj się kolejnych zadań już niebawem!',
			},
			'assign-role': {
				placeholder: 'Wyznacz rolę {roleName}',
				confirm: 'Zatwierdź',
			},
		},
		tasks: {
			title: 'Zadania',
		},
		contacts: {
			title: 'Kontakty',
			roleTitle: 'Rola',
			role: {
				manager: 'Menadżer',
			},
		},
		employees: {
			yours: 'Twoi pracownicy',
		},
	},
};
