import moment, { Moment } from 'moment';
import Action, { ActionDTO } from './Action';

export interface TaskActionDTO extends ActionDTO {
	id: string;
	active_from: string;
	closed_at: string | null;
	deadline: string | null;
	name: string;
	recipient: 'employee' | 'manager' | 'custom-role';
	status: 'ongoing' | 'done' | 'delayed' | 'waiting';
	workflow_action_id: string;
	content_id: string;
	content_name: string;
	content_icon: string | null;
	content_image: string | null;
	icon: string | null;
}

export default class TaskAction extends Action {
	static contentTypes: { [key: string]: string } = {
		'solidsnake-task': 'solidsnake-task',
		'solidsnake-mission': 'solidsnake-mission',
		'solidsnake-form': 'solidsnake-form',
		form: 'form',
		story: 'story',
	};

	private _id: string;
	private _activeFrom: Moment;
	private _closedAt: Moment | null;
	private _deadline: Moment | null;
	private _name: string;
	private _recipient: 'employee' | 'manager' | 'custom-role';
	private _status: 'ongoing' | 'done' | 'delayed' | 'waiting';
	private _workflowActionId: string;
	private _contentId: string;
	private _contentName: string;
	private _contentIcon: string | null;
	private _contentImage: string | null;
	private _icon: string | null;

	constructor(actionDTO: TaskActionDTO) {
		super(actionDTO);

		this._id = actionDTO.id;
		this._activeFrom = moment(actionDTO.active_from);
		this._closedAt = actionDTO.closed_at ? moment(actionDTO.closed_at) : null;
		this._deadline = actionDTO.deadline ? moment(actionDTO.deadline) : null;
		this._name = actionDTO.name;
		this._recipient = actionDTO.recipient;
		this._status = actionDTO.status;
		this._workflowActionId = actionDTO.workflow_action_id;
		this._contentId = actionDTO.content_id;
		this._contentType = actionDTO.content_type;
		this._contentName = actionDTO.content_name;
		this._contentIcon = actionDTO.content_icon;
		this._contentImage = actionDTO.content_image;
		this._icon =
			!actionDTO.icon &&
			[TaskAction.contentTypes['form'], TaskAction.contentTypes['story']].includes(actionDTO.content_type)
				? 'avatar'
				: actionDTO.icon;
	}

	get id() {
		return this._id;
	}

	get activeFrom() {
		return this._activeFrom;
	}

	get closedAt() {
		return this._closedAt;
	}

	get deadline() {
		return this._deadline;
	}

	get name() {
		return this._name;
	}

	get recipient() {
		return this._recipient;
	}

	get isRecipientManagerLike() {
		return ['manager', 'custom-role'].includes(this._recipient);
	}

	get status() {
		return this._status;
	}

	get workflowActionId() {
		return this._workflowActionId;
	}

	get contentId() {
		return this._contentId;
	}

	get contentType() {
		return TaskAction.contentTypes[this._contentType];
	}

	get detailedContentType() {
		return this._contentType;
	}

	get contentName() {
		return this._contentName || this.name;
	}

	get contentIcon() {
		return this._contentIcon;
	}

	get contentImage() {
		return this._contentImage;
	}

	get icon() {
		return this._icon;
	}

	get userFullName() {
		return `${this.user?.forename || ''} ${this.user?.surname || ''}`;
	}

	get isAvailable(): boolean {
		// TODO(poc:jan.gradzki@gamfi.pl, 5-05-2022): Change to this.status when possible.
		return !this.closedAt && new Date().getTime() - this.activeFrom.valueOf() > 0;
	}

	get isDelayed(): boolean {
		// TODO(poc:jan.gradzki@gamfi.pl, 5-05-2022): Change to this.status when possible.
		return !!this.deadline && new Date().getTime() - this.deadline.valueOf() > 0;
	}
}

export const isTaskAction = (action: unknown): action is TaskAction => {
	return action instanceof TaskAction;
};
