import React from 'react';
import { wire } from 'react-hot-wire';
import ProcessService from 'services/onboarding/process/ProcessService';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ActionService from 'services/onboarding/process/action/ActionService';
import { EmployeePortalContextProvider } from 'components/employeePortal/context/employeePortalContext';
import ContactsService from 'services/onboarding/contacts/ContactsService';
import EmployeePortalView from './EmployeePortal.view';

export type PortalProps = InjectedIntlProps & {
	'services.onboardingProcessService': ProcessService;
	'services.onboardingActionService': ActionService;
	'services.onboardingContactsService': ContactsService;
};

export const EmployeePortal = (props: PortalProps) => {
	return (
		<EmployeePortalContextProvider {...props}>
			<EmployeePortalView />
		</EmployeePortalContextProvider>
	);
};

export default injectIntl(
	wire(
		['services.onboardingProcessService', 'services.onboardingActionService', 'services.onboardingContactsService'],
		EmployeePortal
	)
);
